.garage-money-articles {
  margin-top: 1rem;
  padding-left: 0;
  list-style: none; }
  .garage-money-articles li {
    margin-bottom: 1.0625rem;
    padding-bottom: 1.0625rem;
    border-bottom: 1px solid rgba(218, 220, 226, 0.5); }
    .garage-money-articles li:last-child {
      border-bottom: 0; }
  .garage-money-articles a {
    color: #3C404C; }

.garage-money-featured {
  margin-top: 1.5rem;
  display: flex;
  gap: 1.5rem;
  justify-content: space-between; }
  .garage-money-featured .blog-item {
    width: 48%; }

.garage-money-cta {
  display: flex;
  gap: 2rem;
  align-items: center;
  padding: 1.5rem 2rem 0 2rem;
  background-image: url("../../../assets/images/garage-money-bg.png");
  background-repeat: no-repeat;
  background-size: cover; }
  .garage-money-cta-title {
    margin-top: 1.5rem;
    font-size: 1.75rem; }
  .garage-money-cta-subtitle {
    margin-bottom: 1.5rem;
    font-size: 1.125rem; }

.garage-money-subscribe p {
  margin-bottom: .5rem; }

.garage-money-subscribe .btn, .garage-money-subscribe .btn:focus, .garage-money-subscribe .btn:active {
  background-color: #FF8923;
  border-color: #FF8923;
  color: #fff; }

.garage-money-subscribe input {
  border-color: #FF8923;
  background-color: #fff; }
  .garage-money-subscribe input:focus {
    border-color: #FF8923; }

@media screen and (max-width: 991px) {
  .garage-money-cta {
    padding-bottom: 1.5rem; }
    .garage-money-cta-right {
      display: none; }
  .garage-money-featured {
    margin-bottom: 2rem; } }

@media screen and (max-width: 429px) {
  .garage-money-cta {
    padding: 1rem; }
    .garage-money-cta-title {
      font-size: 1.5rem; }
  .garage-money-featured {
    flex-direction: column; }
    .garage-money-featured .blog-item {
      width: auto;
      margin-bottom: 0; } }
