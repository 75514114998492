.blog-posts {
  display: flex;
  justify-content: space-between; }

.blog-item {
  flex: 0 0 auto;
  width: 32%;
  font-size: 0.875rem; }
  .blog-item-thumbnail {
    height: 14.375rem;
    margin-bottom: .8rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 0.5rem; }
    .blog-item-thumbnail img {
      width: 0;
      height: 0;
      opacity: 0; }
  .blog-item-title {
    margin-bottom: .5rem;
    font-size: 0.875rem;
    font-weight: 600; }
  .blog-item-description {
    margin-bottom: .4rem;
    font-size: 0.875rem; }

.blog .line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden; }

@media screen and (max-width: 768px) {
  .blog .section-heading {
    flex-direction: column; }
    .blog .section-heading .section-title {
      margin-bottom: .5rem; }
  .blog-posts {
    flex-direction: column; }
  .blog-item {
    width: 100%;
    margin-bottom: 2rem; } }
