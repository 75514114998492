.statistic-big {
  padding: 2.75rem 0;
  background-color: #373656;
  font-size: 1.125rem;

  .section-title {
    margin-bottom: 1rem;
    font-size: 1.8rem;
  }
  
  &, h2, h3 {
    color: #fff;
  }

  > .container {
    display:  flex;
    gap: 1.5rem;
  }

  &-map {
    flex: 0 0 45%;
    background-image: url('../../../assets/images/map.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .metric {
    &-item {
      &-value {
        font-size: 2.875rem;
        font-weight: 600;
      }

      &-title {
        font-size: 1.25rem;
      }
    }

    &-group .metric-item {
      margin-top: 2rem;
    }
  }
}

@media screen and (max-width: 767px) {
  .statistic-big {
    > .container {
      position: relative;
    }
    
    &-map {
      position: absolute;
      top: 0;
      right: 0;
      flex: none;
      width: 100%;
      height: 100%;
      background-position: center;
      z-index: 1;
      opacity: .2;
    }

    &-text {
      position: relative;
      z-index: 2;
    }
  }
}