.feature-request-filters {
  display: flex;
  gap: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(218, 220, 226, 0.5);
  background-color: #F6F7F8;
  border-top-left-radius: var(--bs-border-radius);
  border-top-right-radius: var(--bs-border-radius); }

.request-uploaded-image {
  display: inline-block;
  position: relative;
  margin-bottom: .5rem;
  margin-right: .5rem;
  border: 1px solid rgba(218, 220, 226, 0.5);
  border-radius: 3px; }
  .request-uploaded-image-delete {
    position: absolute;
    right: -.5rem;
    top: -.3rem;
    display: block;
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    background: #FF7158;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    line-height: 1.4; }

.feature-request-item {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(218, 220, 226, 0.5); }
  .feature-request-item:hover {
    text-decoration: none;
    background-color: #F6F7F8; }
  .feature-request-item .feature-request-title {
    margin-bottom: .3rem;
    font-size: .85rem;
    font-weight: 600; }
  .feature-request-item .feature-request-description {
    max-height: 44px;
    font-size: .85rem;
    overflow: hidden;
    word-wrap: break-word;
    word-break: break-word;
    color: #858B9E; }
    .feature-request-item .feature-request-description .truncate {
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      white-space: normal; }
  .feature-request-item .feature-request-metainfo {
    display: flex;
    gap: .5rem;
    align-items: center;
    margin-top: .5rem;
    color: #858B9E;
    font-size: .8rem; }
    .feature-request-item .feature-request-metainfo .icon {
      background-color: #858B9E;
      width: 12px;
      height: 12px; }
    .feature-request-item .feature-request-metainfo .badge {
      font-size: .65rem;
      font-weight: 500;
      line-height: 1.1; }

.feature-request-vote {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 2.25rem;
  padding: .2rem;
  line-height: 1;
  border: 1px solid rgba(218, 220, 226, 0.5);
  border-radius: .2rem;
  text-align: center;
  font-size: .8rem;
  background-color: #fff; }
  .feature-request-vote .icon {
    background-color: #858B9E; }

.request-details-main {
  padding-left: 3.5rem; }

.request-details-title {
  font-size: 1rem;
  font-weight: 600; }

.request-details-description {
  line-height: 1.6; }

.request-details .feature-request-vote {
  padding-top: .58rem;
  padding-bottom: .58rem; }
  .request-details .feature-request-vote:hover {
    border-color: rgba(189, 193, 204, 0.5); }

.replies-list {
  list-style-type: none;
  padding-left: 0; }
  .replies-list .replies-list {
    margin-top: 1rem;
    padding-left: 1.9rem; }
    .replies-list .replies-list .replies-list-item {
      margin-bottom: .8rem; }
  .replies-list .post-info-avatar, .replies-list .post-info-avatar .avatar-default {
    width: 1.4rem;
    height: 1.4rem;
    font-size: .9rem; }
  .replies-list-item {
    margin-bottom: 1.5rem; }
    .replies-list-item .replies-main {
      padding-top: .1rem;
      padding-left: 1.8rem; }
      .replies-list-item .replies-main a, .replies-list-item .replies-main .btn-link {
        color: #3C404C;
        text-decoration: underline;
        opacity: .8; }
        .replies-list-item .replies-main a:hover, .replies-list-item .replies-main .btn-link:hover {
          text-decoration: none; }
      .replies-list-item .replies-main textarea {
        font-size: .9rem; }
    .replies-list-item .replies-description {
      font-size: .85rem; }
    .replies-list-item .post-info-timestamp {
      font-size: .8rem; }

.image-modal .modal-content {
  background-color: transparent;
  width: auto;
  margin: 0 auto;
  border: 0; }
