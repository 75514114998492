.page-affiliates.inner-page {
  padding-top: 4rem;
  padding-bottom: 2rem; }

.page-affiliates .inner-page-header {
  text-align: center; }
  .page-affiliates .inner-page-header .container::after {
    display: none; }

.page-affiliates .inner-page-title {
  font-size: 4.4rem; }

.page-affiliates .inner-page-subtitle {
  margin-top: 1rem;
  font-size: .95rem;
  color: #858B9E; }

.page-affiliates .testimonial-section {
  margin-top: 3rem;
  margin-bottom: 2.5rem; }
  .page-affiliates .testimonial-section .section-title {
    display: none; }
  .page-affiliates .testimonial-section .feature-video {
    margin-bottom: 0; }

.affiliate-signup {
  max-width: 53rem;
  padding: 1.5rem 4rem;
  margin: 0 auto;
  border: 1px solid rgba(218, 220, 226, 0.5);
  border-radius: 1rem;
  background-color: #383f4c;
  color: #fff;
  text-align: center; }
  .affiliate-signup-title {
    margin-bottom: 1.5rem;
    line-height: 1.4;
    color: #fff; }
  .affiliate-signup .btn-primary {
    padding-left: 3rem;
    padding-right: 3rem;
    text-transform: uppercase; }

@media screen and (max-width: 450px) {
  .affiliate-signup {
    padding: 1.5rem; }
    .affiliate-signup-title {
      font-size: 1.1rem; } }
