.page-press .brand-colors {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .page-press .brand-colors .color-item {
    width: 10rem;
    height: 10rem;
    padding: 1rem;
    position: relative;
    color: #fff; }
    .page-press .brand-colors .color-item .color-code {
      position: absolute;
      left: .8rem;
      bottom: .5rem;
      font-weight: 600; }
      .page-press .brand-colors .color-item .color-code span {
        display: block; }

.page-press .list-rules {
  margin-top: 1rem;
  margin-bottom: 0;
  padding-left: 1rem; }
  .page-press .list-rules > li {
    margin-bottom: .5rem; }
