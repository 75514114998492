.pricing-plan {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 2rem; }
  @media (max-width: 991px) {
    .pricing-plan {
      grid-template-columns: 1fr;
      gap: 1.5rem; } }
  .pricing-plan-box {
    position: relative;
    padding: 2rem;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 0 1px rgba(60, 64, 76, 0.02), rgba(149, 157, 165, 0.4) 0px 8px 24px; }
    .pricing-plan-box .recommended-badge {
      position: absolute;
      top: -1rem;
      left: 50%;
      transform: translateX(-50%);
      padding: .15rem .3rem;
      background: #fff;
      border: 2px solid #FCB036;
      border-radius: .3rem; }
    .pricing-plan-box .recommended-text {
      display: flex;
      align-items: center;
      gap: .25rem;
      font-weight: 800;
      background: #ff0000;
      background: linear-gradient(to top, #ff0000, #fdcf58);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; }
  .pricing-plan-price {
    min-height: 5rem;
    margin: 0 -2rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid rgba(218, 220, 226, 0.5);
    text-align: center; }
    .pricing-plan-price .number {
      font-size: 2rem;
      font-weight: 600; }
      @media (max-width: 991px) {
        .pricing-plan-price .number {
          font-size: 1.75rem; } }
    .pricing-plan-price .period {
      margin-left: .3rem; }
    .pricing-plan-price .old-price {
      text-decoration: line-through;
      text-decoration-color: #696c77;
      margin-right: .3rem;
      font-size: 1rem;
      font-weight: 600;
      opacity: .75; }
    .pricing-plan-price .notes {
      color: #0d6efd; }
  .pricing-plan-details {
    margin-top: 1rem; }
  .pricing-plan-footer {
    margin-top: 1.5rem; }
    .pricing-plan-footer .btn {
      display: block;
      padding: .5rem 1rem;
      font-size: 1.2rem; }
      @media (max-width: 991px) {
        .pricing-plan-footer .btn {
          font-size: 1.1rem;
          padding: .75rem 1rem; } }
  .pricing-plan .benefit-list {
    margin: 0;
    padding-left: .5rem;
    text-align: left; }
    .pricing-plan .benefit-list li {
      margin-bottom: .5rem;
      color: #3C404C;
      list-style: none;
      display: flex;
      align-items: center; }
      .pricing-plan .benefit-list li::before {
        flex: 0 0 auto;
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill-rule='nonzero' fill='%2300C853'%3E%3Cpath d='m16.637 7.663-.93-.957a.2.2 0 0 0-.148-.064.192.192 0 0 0-.148.064l-6.452 6.5-2.348-2.349a.209.209 0 0 0-.148-.063.209.209 0 0 0-.149.063l-.94.942a.21.21 0 0 0 0 .301l2.96 2.962c.191.19.424.301.62.301.28 0 .523-.206.613-.29h.005l7.07-7.109c.075-.09.075-.222-.005-.301Z'/%3E%3Cpath d='M11 1.48c2.544 0 4.934.99 6.732 2.788A9.456 9.456 0 0 1 20.52 11a9.456 9.456 0 0 1-2.787 6.732A9.456 9.456 0 0 1 11 20.52a9.456 9.456 0 0 1-6.732-2.787A9.456 9.456 0 0 1 1.48 11c0-2.544.989-4.934 2.787-6.732A9.456 9.456 0 0 1 11 1.48ZM11 0C4.924 0 0 4.924 0 11s4.924 11 11 11 11-4.924 11-11S17.076 0 11 0Z'/%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 18px;
        height: 18px;
        display: inline-block;
        margin-left: -.6rem;
        margin-right: .6rem; }
      .pricing-plan .benefit-list li:last-child {
        margin-bottom: 0; }
      @media (max-width: 991px) {
        .pricing-plan .benefit-list li {
          font-size: .95rem; } }
    .pricing-plan .benefit-list.smaller {
      padding-left: .5rem; }
      .pricing-plan .benefit-list.smaller li {
        font-size: 1rem; }
        @media (max-width: 991px) {
          .pricing-plan .benefit-list.smaller li {
            font-size: .9rem; } }
    .pricing-plan .benefit-list.blue li::before {
      background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill-rule='nonzero' fill='%233D5FC0'%3E%3Cpath d='m16.637 7.663-.93-.957a.2.2 0 0 0-.148-.064.192.192 0 0 0-.148.064l-6.452 6.5-2.348-2.349a.209.209 0 0 0-.148-.063.209.209 0 0 0-.149.063l-.94.942a.21.21 0 0 0 0 .301l2.96 2.962c.191.19.424.301.62.301.28 0 .523-.206.613-.29h.005l7.07-7.109c.075-.09.075-.222-.005-.301Z'/%3E%3Cpath d='M11 1.48c2.544 0 4.934.99 6.732 2.788A9.456 9.456 0 0 1 20.52 11a9.456 9.456 0 0 1-2.787 6.732A9.456 9.456 0 0 1 11 20.52a9.456 9.456 0 0 1-6.732-2.787A9.456 9.456 0 0 1 1.48 11c0-2.544.989-4.934 2.787-6.732A9.456 9.456 0 0 1 11 1.48ZM11 0C4.924 0 0 4.924 0 11s4.924 11 11 11 11-4.924 11-11S17.076 0 11 0Z'/%3E%3C/g%3E%3C/svg%3E"); }
  .pricing-plan-box.recommended {
    border: 2px solid #FCB036; }
    .pricing-plan-box.recommended .pricing-plan-price {
      padding-bottom: 0; }
    .pricing-plan-box.recommended .notes {
      color: #0d6efd; }
