.section-footer-cta {
  padding: 9.9rem 0;
  margin-bottom: 3rem;
  position: relative;
  background-color: #111D16; }
  .section-footer-cta .footer-chart {
    position: absolute;
    top: -8.5rem;
    right: 0; }
  .section-footer-cta .footer-cta {
    position: relative;
    top: -5rem; }

.page-footer {
  padding: 5rem 0 1rem 0;
  background-color: #111D16;
  position: relative; }
  .page-footer::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    min-width: 552px;
    height: 100%;
    background-image: url("../../../assets/images/footer-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    mix-blend-mode: luminosity;
    opacity: .75;
    z-index: 1; }
  .page-footer, .page-footer p, .page-footer a {
    color: rgba(255, 255, 255, 0.7); }
  .page-footer > .container > .inner {
    display: flex;
    justify-content: space-between; }
    .page-footer > .container > .inner .footer-col {
      flex: 0 0 auto;
      position: relative;
      z-index: 2; }
  .page-footer a:hover, .page-footer a:focus {
    color: #fff;
    text-decoration: none; }
  .page-footer ul.list-2-col {
    column-count: 2; }
  .page-footer ul li {
    position: relative;
    margin-bottom: .4rem;
    z-index: 2; }
  .page-footer .footer-heading {
    margin-bottom: 1rem;
    color: #fff;
    font-size: 1rem;
    font-weight: 600; }
  .page-footer .social-icons {
    margin: 1rem 0 .5rem 0; }
    .page-footer .social-icons a {
      margin-right: .5rem; }
  .page-footer .copyright {
    margin-top: 2rem;
    margin-bottom: 0;
    text-align: center;
    font-size: .9rem; }
  .page-footer .trademarks {
    font-size: .8rem;
    color: rgba(255, 255, 255, 0.5); }
    .page-footer .trademarks strong {
      display: block;
      text-align: center; }
    .page-footer .trademarks ul {
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      margin: 0;
      padding: 0; }
      .page-footer .trademarks ul li {
        margin: 0 .5rem; }

@media screen and (max-width: 992px) {
  .section-footer-cta {
    margin-bottom: 0; } }

@media screen and (max-width: 850px) {
  .section-footer-cta {
    padding: 8rem 0; }
  footer > .container > .inner {
    flex-wrap: wrap; }
    footer > .container > .inner .footer-col {
      flex: 0 0 50%;
      margin-bottom: 1rem; } }

@media screen and (max-width: 768px) {
  .section-footer-cta {
    padding: 6rem 0; } }

@media screen and (max-width: 600px) {
  .section-footer-cta {
    padding: 4rem 0; } }

@media screen and (max-width: 568px) {
  .page-footer::after {
    min-width: 375px;
    opacity: .5; } }

@media screen and (max-width: 390px) {
  .section-footer-cta {
    padding: 2rem 0; }
  footer > .container > .inner {
    flex-direction: column; }
    footer > .container > .inner .footer-col:first-child {
      margin-bottom: 2rem; } }
