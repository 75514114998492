.page-about.inner-page {
  padding-bottom: 13rem; }

.page-about .inner-page-content > .container {
  position: relative; }

.page-about-image {
  position: absolute;
  top: -9rem;
  right: -3rem; }

@media screen and (max-width: 991px) {
  .page-about {
    padding-bottom: 4rem; }
    .page-about.inner-page {
      padding-bottom: 4rem; }
    .page-about-image {
      position: static; } }
