.inner-page.reviews .inner-page-title {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.6; }

.inner-page.reviews .inner-page-header {
  max-width: 590px;
  margin-left: auto;
  margin-right: auto; }

.inner-page.reviews .inner-page-content {
  font-size: 1.1rem;
  line-height: 2rem; }
  .inner-page.reviews .inner-page-content > .container {
    max-width: 590px;
    margin: 0 auto; }

.inner-page.reviews .testimonial-section {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto; }
  .inner-page.reviews .testimonial-section .testimonial {
    height: 246px;
    line-height: 1.6; }
  .inner-page.reviews .testimonial-section .feature-video {
    margin-bottom: 0; }

.reviews-screenshot {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  border-radius: .5rem;
  overflow: hidden; }
  .reviews-screenshot.bordered {
    border: 1px solid rgba(218, 220, 226, 0.5); }

@media screen and (max-width: 991px) {
  .inner-page.reviews .testimonial-section .testimonial-col {
    margin-bottom: 1rem; }
    .inner-page.reviews .testimonial-section .testimonial-col .feature-video {
      margin: 0 auto; } }
