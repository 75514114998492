.feature .section-title {
  margin-bottom: 2rem;
  font-size: 3.9rem;
  text-align: center; }
  .feature .section-title br {
    display: none; }

.feature-row {
  margin-bottom: 3rem;
  justify-content: space-between; }

.feature-col-video {
  flex: 0 0 auto !important;
  width: 40% !important; }

.feature-col-image {
  flex: 0 0 auto  !important;
  width: 59% !important; }

.feature-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.4rem; }

.feature-video {
  margin-bottom: 2.5rem;
  position: relative; }
  .feature-video-overlay {
    position: absolute;
    top: .5rem;
    left: .5rem;
    padding: .25rem .5rem;
    border-radius: .25rem;
    background-color: rgba(0, 0, 0, 0.5); }
  .feature-video-title {
    margin: 0;
    font-size: 0.9rem;
    font-weight: 500;
    color: #fff; }

.feature-item {
  margin-bottom: 1.2rem; }
  .feature-item-title {
    font-size: 1.2rem;
    font-weight: 500; }
  .feature-item-box {
    position: relative;
    border-radius: .7rem;
    overflow: hidden; }
    .feature-item-box:hover .feature-item-benefit {
      opacity: 1; }
  .feature-item-benefit {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 0;
    padding: 2rem;
    position: absolute;
    top: 0;
    transition: all .3s ease-in-out;
    background-color: rgba(0, 0, 0, 0.85);
    color: #fff;
    text-align: center; }

.feature-tabs {
  margin-bottom: 1rem; }
  .feature-tabs .nav {
    margin-bottom: 1rem; }
  .feature-tabs .tab-content img {
    margin-top: 1rem; }
  .feature-tabs .tab-transition {
    display: block !important;
    position: absolute;
    width: 100%;
    transition: opacity 0.3s ease-in-out; }

.feature-convert {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: .5rem 1rem;
  background-color: rgba(53, 85, 180, 0.1);
  color: #002740;
  border-radius: .3rem; }
  .feature-convert .btn {
    flex: 1 0 auto; }

@media screen and (max-width: 1279px) {
  .feature .section-title {
    font-size: 3.4rem; } }

@media screen and (max-width: 991px) {
  .feature .section-title {
    font-size: 2.55rem; }
  .feature-row {
    flex-direction: column; }
  .feature-col-video {
    width: 100% !important;
    display: flex;
    gap: 1rem;
    order: 1; }
  .feature-video {
    flex: 0 0 auto;
    width: 49%;
    margin-bottom: 1rem; }
  .feature-col-image {
    width: 100% !important;
    order: 0;
    margin-bottom: 1rem; }
  .feature .col-sm-12 {
    order: 3; } }

@media screen and (max-width: 767px) {
  .feature .section-title {
    font-size: 1.9rem; } }

@media screen and (max-width: 485px) {
  .feature .section-title {
    font-size: 2rem; }
    .feature .section-title br {
      display: block; }
  .feature-title {
    font-size: 1.2rem; }
  .feature-col-video {
    flex-direction: column; }
  .feature-video {
    width: 100%; }
  .feature-convert {
    flex-direction: column;
    text-align: center; } }

.animated-border {
  position: relative;
  border-radius: 0.375rem;
  padding: 4px; }
  .animated-border .inner {
    border-radius: 4px; }
  .animated-border::before, .animated-border::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(45deg, #00943E, #00C854, #D4F2E1);
    background-size: 400%;
    z-index: -1;
    animation: glow 5s linear infinite;
    width: 100%;
    border-radius: 0.375rem; }
  .animated-border::after {
    filter: blur(14px);
    transform: translate3d(0, 0, 0); }
  .animated-border .feature-video {
    height: 246px;
    margin-bottom: 0; }

@keyframes glow {
  0% {
    background-position: 0 0; }
  50% {
    background-position: 100% 0; }
  100% {
    background-position: 0 0; } }
