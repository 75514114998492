.navbar-nav {
  --bs-nav-link-padding-y: .7rem !important;
  align-items: center; }
  .navbar-nav .nav-item .btn {
    margin: 0 .5rem; }
  .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.75); }
    .navbar-nav .nav-link:hover {
      color: #fff; }
    .navbar-nav .nav-link.active {
      --bs-navbar-active-color: $dark;
      background-color: #fff;
      position: relative; }
      .navbar-nav .nav-link.active::before, .navbar-nav .nav-link.active::after {
        content: "";
        position: absolute;
        top: 0;
        width: 8px;
        height: 100%;
        background-color: #002740; }
      .navbar-nav .nav-link.active::before {
        left: -.15rem;
        border-bottom-right-radius: 6px; }
      .navbar-nav .nav-link.active::after {
        right: -.15rem;
        border-bottom-left-radius: 6px; }
      .navbar-nav .nav-link.active:hover {
        text-decoration: none; }
  .navbar-nav .btn-sm {
    font-weight: 500; }

.navbar-brand img {
  height: 1.4rem; }

@media screen and (max-width: 1279px) {
  .navbar-nav .nav-link {
    border-radius: 3px; }
  .navbar-nav .nav-link.active::before,
  .navbar-nav .nav-link.active::after {
    display: none; } }

@media screen and (max-width: 991px) {
  .navbar {
    padding: .2rem 0 !important; }
  .navbar-nav {
    align-items: flex-start; }
    .navbar-nav .nav-item .btn {
      margin: .5rem 0 .5rem 0; }
    .navbar-nav .nav-link {
      padding: .3rem .4rem; } }
