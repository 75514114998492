.admin .community-profile-info-avatar, .admin .community-profile-info-avatar .icon-user {
  width: 1.5rem;
  height: 1.5rem; }

.admin-topic-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: .4rem .6rem;
  margin-bottom: .5rem;
  border: 1px solid rgba(218, 220, 226, 0.5);
  border-radius: .2rem; }
  .admin-topic-item:last-child {
    margin-bottom: 0; }
