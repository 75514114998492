.testimonial-section .slick-slider {
  padding: 0 .2rem; }

.testimonial {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(236, 236, 236, 0.25);
  border-radius: 0.375rem;
  border: 1px solid rgba(218, 220, 226, 0.5); }
  .testimonial-group {
    display: inline-flex !important;
    justify-content: space-evenly; }
    .testimonial-group > .testimonial {
      flex: 0 0 auto;
      width: 24%; }
  .testimonial-avatar {
    flex: 0 0 auto;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: #00C854;
    text-align: center;
    color: #fff;
    font-size: 1rem;
    line-height: 2;
    text-transform: uppercase; }
    .testimonial-avatar img {
      width: 0;
      height: 0;
      opacity: 0; }
  .testimonial-text {
    margin-bottom: 1rem;
    font-size: .95rem; }
  .testimonial-author {
    display: flex;
    align-items: center;
    gap: .5rem; }
  .testimonial-name {
    font-size: .9rem;
    font-weight: 500;
    margin-bottom: 0; }
  .testimonial .icon-star {
    width: 22px;
    height: 22px;
    background-color: #FFB621; }

@media screen and (max-width: 991px) {
  .testimonial-section > .container {
    max-width: none;
    padding-left: 2rem;
    padding-right: 2rem; }
  .testimonial-group > .testimonial {
    width: 48%; } }

@media screen and (max-width: 669px) {
  .testimonial-group > .testimonial {
    width: 100%; } }
