.profit-proof .slick-slider {
  padding: 0 .2rem;
  margin: 0 -1rem; }

.proof-item-inner {
  position: relative;
  margin: 1rem;
  border-radius: .5rem;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.21); }

.proof-item-top {
  background-color: #373656;
  color: #fff;
  text-align: center;
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem; }

.proof-item-user {
  margin: 2.5rem auto .75rem auto;
  position: relative;
  top: -2.5rem; }
  .proof-item-user-image {
    width: 5rem;
    height: 5rem;
    margin: 0 auto .75rem auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    border: 3px solid #fff; }
    .proof-item-user-image img {
      width: 0;
      height: 0;
      opacity: 0; }
  .proof-item-user-name {
    margin-bottom: 0;
    font-size: 1.3rem;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    line-height: 1; }

.proof-item-bottom {
  padding: 0 2rem;
  height: 15rem;
  position: relative; }
  .proof-item-bottom-inner {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 17rem;
    min-height: 17.5rem;
    transform: translateX(-50%);
    padding: .8rem 1.5rem;
    box-shadow: 0px -3px 13px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem; }

.proof-item-profit {
  margin-bottom: .25rem;
  font-size: 2rem;
  font-weight: 700;
  color: #00943E;
  text-align: center; }

.proof-item-details {
  margin-top: 1rem; }
  .proof-item-details p {
    text-align: center;
    margin-bottom: .35rem; }
  .proof-item-details-icon {
    display: flex;
    gap: .5rem;
    align-items: center; }

.proof-item-quote {
  margin-top: 1rem;
  font-style: italic;
  font-size: .85rem;
  line-height: 1.4; }
