.table-alternatives th, .table-alternatives td {
  font-weight: normal;
  border-color: rgba(218, 220, 226, 0.5); }

.table-alternatives thead th {
  background-color: #F6F7F8;
  font-weight: 600; }

.table-alternatives-highlight {
  background-color: rgba(0, 148, 62, 0.05) !important; }

.table-alternatives td .icon {
  width: 17px;
  height: 17px;
  position: relative;
  top: -2px;
  margin-right: .3rem; }
  .table-alternatives td .icon-check-square {
    background-color: #00943E; }
  .table-alternatives td .icon-x-square {
    background-color: #FF7158; }

.comparison-group {
  display: grid;
  grid-template-columns: 45% 45%;
  column-gap: 10%; }

.card-comparison {
  margin-bottom: 2rem;
  color: inherit;
  background-color: rgba(236, 236, 236, 0.25);
  border: 1px solid rgba(218, 220, 226, 0.5); }
  .card-comparison .card-body {
    height: 100%; }
  .card-comparison-title {
    font-size: 1.2rem;
    font-weight: 500; }
  .card-comparison p {
    flex: 1 1 auto;
    line-height: 1.6;
    color: #6A7186; }
  .card-comparison .card-top {
    padding: 2rem 1.5rem;
    font-size: 1.5rem;
    font-weight: 600;
    background-color: #00273F;
    color: #fff;
    text-align: center;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px; }
    .card-comparison .card-top .vs {
      display: block; }
  .card-comparison .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .card-comparison .compare-link {
    flex: 0;
    color: #00943E;
    text-decoration: none; }
    .card-comparison .compare-link:hover, .card-comparison .compare-link:focus {
      text-decoration: underline; }

.inner-page.alternatives .testimonial-section {
  margin-top: 2rem; }
  .inner-page.alternatives .testimonial-section .section-heading {
    display: none; }

@media screen and (max-width: 767px) {
  .comparison-group {
    display: block; } }
