.hero {
  margin-bottom: 3rem !important;
  background-image: url("../../../assets/images/hero-blur.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 46% 0; }
  .hero .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 3.5rem; }
  .hero-left {
    flex: 0 0 auto;
    width: 45%; }
  .hero-right {
    flex: 0 0 auto;
    width: 50%; }
  .hero-img {
    width: auto;
    height: 20.6875rem;
    background-repeat: no-repeat;
    background-size: cover; }
    .hero-img img {
      opacity: 0;
      width: 0;
      height: 0; }
    .hero-img-wrapper {
      padding: .5rem;
      margin-bottom: 1rem;
      background-color: #fff;
      border-radius: .5rem;
      box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05); }
      .hero-img-wrapper:hover {
        animation-name: wiggle;
        animation-duration: 1000ms;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out; }
  .hero-title {
    font-size: 1.6rem;
    line-height: 1.6;
    font-weight: 600; }
    .hero-title #plusEbayListing {
      font-weight: 500;
      letter-spacing: -2px; }
  .hero-subtitle {
    margin-top: 1.2rem;
    margin-bottom: 2rem;
    font-size: 1.1rem;
    line-height: 1.6; }
  .hero-primary-btn .icon {
    transition: all .15s ease-out; }
  .hero-primary-btn:hover .icon {
    transform: translateX(0.3rem); }
  .hero-cta-fomo {
    margin-top: .5rem;
    display: flex;
    align-items: center;
    gap: .5rem;
    font-size: .82rem;
    line-height: 1.4; }
    .hero-cta-fomo p {
      margin: 0;
      text-align: left; }

@media screen and (max-width: 992px) {
  .hero {
    background-position: 50% 15rem;
    text-align: center; }
    .hero .container {
      flex-direction: column;
      padding-top: 2rem;
      padding-bottom: 1rem; }
    .hero-left {
      width: 100%;
      margin-bottom: 1.5rem; }
    .hero-right {
      width: 100%; }
    .hero-title {
      font-size: 1.3rem; }
    .hero-subtitle {
      font-size: 1rem; }
    .hero-primary-btn .icon {
      display: none; }
    .hero-cta-fomo {
      justify-content: center; } }

.ebay-e {
  color: #e53238; }

.ebay-b {
  color: #0064d2; }

.ebay-a {
  color: #f5af02; }

.ebay-y {
  color: #86b817; }

@media screen and (max-width: 410px) {
  .hero-cta-fomo br {
    display: none; } }
