.profit-hunt-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem; }

.profit-hunt-features {
  flex: 0 0 30%;
  display: flex;
  flex-direction: column;
  gap: 1rem; }

.profit-hunt-app {
  width: 276px; }

.profit-hunt-feature-title {
  margin-bottom: .2rem;
  font-size: 1rem;
  font-weight: 600; }

.profit-hunt-feature-description {
  font-size: .875rem; }

.profit-hunt .slick-slider .slick-dots {
  bottom: -.5rem; }

.profit-hunt-download {
  display: flex;
  flex-direction: column;
  gap: .9rem; }

.profit-hunt-scan {
  margin-bottom: 1.5rem;
  padding: .8rem 1.2rem;
  border-radius: .3rem;
  text-align: center;
  color: #3C404C;
  border-top: 1px solid rgba(218, 220, 226, 0.5);
  border-bottom: 1px solid rgba(218, 220, 226, 0.5); }

@media screen and (max-width: 991px) {
  .profit-hunt > .container {
    max-width: none; } }

@media screen and (max-width: 768px) {
  .profit-hunt-content {
    flex-direction: column; }
  .profit-hunt-features {
    flex: 0 0 auto;
    width: 100%;
    order: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between; }
  .profit-hunt-feature-item {
    flex: 0 0 auto;
    width: 48%; }
  .profit-hunt-app {
    order: 2; } }

@media screen and (max-width: 439px) {
  .profit-hunt-features {
    gap: 0; }
  .profit-hunt-feature-item {
    width: 100%; }
    .profit-hunt-feature-item:last-child p {
      margin-bottom: 0; } }
