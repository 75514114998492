.page-faq .ebay-counter-text {
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-weight: normal; }

.page-faq .ebay-counter-number {
  display: block;
  margin-right: 1rem;
  font-size: 3rem;
  font-weight: 600;
  color: #00943E; }

.page-faq .chart-ebay-custom-tooltip {
  background-color: var(--bs-body-bg);
  margin: -10px;
  padding: .3rem .5rem;
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  color: #3C404C;
  font-size: 1rem; }

.page-faq .faq-nav {
  position: sticky;
  top: 0;
  padding-top: 4rem; }
  .page-faq .faq-nav .nav-link {
    color: #3C404C;
    font-size: 1rem;
    font-weight: 500;
    border-left: 1px solid rgba(218, 220, 226, 0.5);
    transition: all .3s ease-in-out; }
    .page-faq .faq-nav .nav-link.active {
      color: #00943E;
      font-weight: 700;
      border-left: 1px solid #00943E; }

.page-faq .card-faq {
  margin-bottom: 2.5rem; }
  .page-faq .card-faq .card-title {
    margin-bottom: 1.5rem;
    font-size: 1.4rem;
    font-weight: 500; }

.page-faq .faq-grid {
  column-count: 2;
  column-gap: 4rem; }
  .page-faq .faq-grid article {
    margin-bottom: 1.8rem; }
  .page-faq .faq-grid .question {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.4; }
  .page-faq .faq-grid .answer {
    color: #6b7177;
    word-break: break-word; }

@media screen and (max-width: 991px) {
  .page-faq .faq-nav-wrapper {
    position: sticky;
    top: 0;
    z-index: 9;
    background-color: #fff; }
  .page-faq .faq-nav {
    padding-top: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); }
    .page-faq .faq-nav ul.nav {
      flex-direction: row !important; }
    .page-faq .faq-nav .nav-link {
      padding-left: .5rem;
      padding-right: .5rem;
      font-size: .9rem;
      border-left: 0 !important;
      border-bottom: 2px solid transparent; }
      .page-faq .faq-nav .nav-link.active {
        border-color: #00943E;
        font-weight: 500; } }

@media screen and (max-width: 570px) {
  .page-faq .faq-grid {
    column-count: 1; } }
