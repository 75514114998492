.video-player {
  position: relative; }
  .video-player video {
    padding: 0;
    width: 100%;
    height: 246px;
    object-fit: cover;
    border-radius: 6px;
    background-color: #000; }
  .video-player .btn-primary {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 200, 84, 0.45);
    border-color: rgba(0, 200, 84, 0.15);
    transition: all .3s ease-in; }
    .video-player .btn-primary .icon {
      position: relative;
      top: 2px; }
  .video-player.playing .btn-primary {
    opacity: 0; }
  .video-player.playing:hover .btn-primary {
    opacity: 1; }
